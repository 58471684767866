import React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

function Alternatives() {
  return (
    <Layout>
      <SEO
        title="See how Nickelled Compares to the competition"
        description="We want you to choose the best user onboarding tool for you, even if it’s not us. Here's how things stack up"
      />
      <PageTitle
        preheader=""
        title="Nickelled Alternatives"
        subtitle="We want you to choose the best user onboarding tool for you, even if it’s not us. Here’s how we compare to the rest."
      ></PageTitle>

      <div class="pb-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 items-center mb-24">
            <div className="">
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  User Onboarding Software
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  If you make software, there are a ton of options to welcome
                  your new users. Read our guide on how to pick the best one for
                  you.
                </p>
              </div>
              <Link
                to="/user-onboarding-software/"
                class="relative flex-1 inline-flex items-center justify-center text-md text-oOrange font-medium mb-16"
              >
                <div class="flex-1 flex justify-between">
                  <span class="mr-4">Read The Full Guide</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-oOrange w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
              </Link>
              <p>
                <Link
                  to="/alternatives/helppier/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Helppier
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/appcues-alternative/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Nickelled vs Appcues
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/chameleon/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Nickelled vs Chameleon
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/iorad/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Nickelled vs Iorad
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/tutorialize-alternative/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Nickelled vs Tutorialize
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/tour-my-app-alternative/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Nickelled vs Tour My App
                </Link>
              </p>
              <p>
                <Link
                  to="/blog/userguiding-vs-appcues/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  UserGuiding vs Appcues
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/userflow/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Userflow
                </Link>
              </p>
            </div>
            <div className="">
              <StaticImage
                src="../images/alternatives/user-welcome-pic.jpeg"
                className="w-full rounded-xl shadow-xl"
                alt="Welcome picture"
              />
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 items-center">
            <div className="">
              <StaticImage
                src="../images/alternatives/digital-adoption.jpeg"
                className="w-full rounded-xl shadow-xl"
                alt="Welcome picture"
              />
            </div>
            <div className="">
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Digital Adoption Software
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Training staff inside your organization? Rely on our easy
                  comparisons to see which product will work best for your
                  workforce.
                </p>
              </div>
              <Link
                to="/salesforce-training/digital-adoption-platform/"
                class="relative flex-1 inline-flex items-center justify-center text-md text-oOrange font-medium mb-16"
              >
                <div class="flex-1 flex justify-between">
                  <span class="mr-4">Read The Full Guide</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-oOrange w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </div>
              </Link>
              <p>
                <Link
                  to="/alternatives/apty/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Apty Alternative
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/edcast-myguide/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Edcast MyGuide Alternative
                </Link>
              </p>
              <p>
                <Link
                  to="/blog/pendo-vs-walkme/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Pendo vs Walkme
                </Link>
              </p>
              <p>
                <Link
                  to="/blog/pendo-vs-whatfix/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Pendo vs Whatfix
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/spekit/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  Spekit Alternative
                </Link>
              </p>
              <p>
                <Link
                  to="/blog/walkme-vs-whatfix/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  WalkMe vs Whatfix
                </Link>
              </p>
              <p>
                <Link
                  to="/alternatives/walkme-alternative/"
                  class="text-oOrange underline hover:text-oOrange-dark"
                >
                  WalkMe Alternative
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Alternatives
